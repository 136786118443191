//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    delDocumentBill,
    documentBillAuditedPage,
    documentBillAuditPage,
} from '@/api/modular/fileStream/documentManage'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin from '../components/minxin'
import searchBox from '../components/searchBox'
import { endColumns, isFalse, satrtColumns } from './config'
export default {
    components: {
        STable,
        searchBox,
        OpenData,
    },
    mixins: [minxin],
    props: {
        approvalTab: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        columns() {
            let arr = []
            arr = [...satrtColumns, ...endColumns]
            if (this.approvalTab === 1) {
                arr = [
                    ...satrtColumns,
                    { title: '審批人', width: '150px', dataIndex: 'userId', scopedSlots: { customRender: 'userId' } },
                    ...endColumns,
                ]
            }
            return arr
        },
    },
    watch: {
        approvalTab: {
            handler(val) {
                if (!isFalse(val)) {
                    this.queryParam.approvalTab = val
                    this.$refs.table && this.$refs.table.refresh(true)
                }
            },
            immediate: true,
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            // 查詢參數
            queryParam: { approvalTab: 1, documentType: 1 },
            // 表頭
            // columns: defaultColumns,
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                if (this.approvalTab === 3) {
                    const obj = Object.assign(parameter, this.queryParam)
                    return documentBillAuditedPage({ pageNo: obj.pageNo, pageSize: obj.pageSize })
                        .then((res) => {
                            const data = { ...res.data }
                            data.rows = data.rows.map((ele) => {
                                ele.draftId = formatDraftId(ele.draftId)
                                return ele
                            })
                            return data
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                return documentBillAuditPage(Object.assign(parameter, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    return data
                })
            },
        }
    },
    methods: {
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.$refs.table.refresh(true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi({ approvalId, nodeId, id }) {
            this.$router.push({ name: 'mobileApproval', query: { id, approvalId, nodeId } })
        },
        see(record) {
            this.$router.push({ name: 'showMobileApproval', query: { id: record.id } })
        },
        Filter(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        onSearch() {
            this.$refs.table.refresh(true)
        },
    },
}
